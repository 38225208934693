import styled from "styled-components";

export const Container = styled.div`
  background: #EBEBEB;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  border-top: ${(props) => (props.border ? "1px solid #CDD1D4" : "")};
`;
